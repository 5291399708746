.services-banner {
    background: url('../../assets/servicePage/header.jpg') no-repeat center center/cover;
    position: relative;
    color: #fff;
    text-align: center;
    padding: 5rem 0;
    height: 500px;
    margin-top: 80px;

    .overlay {
        position: relative;
        z-index: 1;

        h1 {
            font-size: 3rem;
            font-weight: bold;
            margin-bottom: 1rem;
        }

        p {
            font-size: 1.25rem;
        }
    }
}