.footer {
    background-color: #343a40; // Dark background color
    color: #d3d3d3; // Light text color

    .container {
        .row {

            .col-lg-3,
            .col-md-6 {
                h4 {
                    color: #ffffff; // Footer headers
                    margin-bottom: 1.5rem;
                }

                p {
                    font-size: 0.95rem;
                    margin-bottom: 1rem;
                    color: #d3d3d3;

                    i {
                        color: #00aaff; // Icon color
                        margin-right: 0.8rem;
                    }
                }

                .btn-social {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 2.5rem;
                    height: 2.5rem;
                    border-radius: 50%;
                    margin-right: 0.5rem;
                    border: 1px solid #d3d3d3;

                    i {
                        color: #00aaff; // Social icon color
                    }

                    &:hover {
                        background-color: #00aaff;
                        border-color: #00aaff;

                        i {
                            color: #fff;
                        }
                    }
                }

                .btn-link {
                    color: #d3d3d3; // Links color
                    text-decoration: none;
                    font-size: 0.95rem;

                    &:hover {
                        color: #00aaff; // Hover link color
                        text-decoration: underline;
                    }
                }

                input[type="text"] {
                    background-color: #444;
                    color: #fff;
                    border: none;
                    padding: 1rem;
                    border-radius: 5px;

                    &::placeholder {
                        color: #d3d3d3;
                    }
                }

                .btn-primary {
                    background-color: #00aaff;
                    border: none;
                    padding: 0.75rem 1.25rem;

                    &:hover {
                        background-color: #007acc;
                    }
                }
            }
        }

        .copyright {
            border-top: 1px solid #555;
            padding-top: 1rem;
            margin-top: 2rem;
            text-align: center;
            font-size: 0.85rem;

            a {
                color: #00aaff;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}